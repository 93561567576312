import {
  ElementType,
  MapElementDto,
  RobotEdgePropertiesDto,
} from '@cartken/map-types';
import { Subject } from 'rxjs';

export interface BlockageUpdate {
  mapElementId: number;
  isBlocked: boolean;
  blockedUntil: Date | undefined;
}

export class BlockageManager {
  private changedMapElementIds = new Map<number, boolean>();
  private _onChange$ = new Subject<void>();
  readonly onChange$ = this._onChange$.asObservable();

  constructor() {}

  toggleBlockedProperty(mapElement: MapElementDto) {
    const mapElementId = mapElement.id;
    if (this.changedMapElementIds.has(mapElementId)) {
      this.changedMapElementIds.delete(mapElementId);
    } else {
      const props = mapElement.properties;
      const isBlocked = props && 'blockedAt' in props && props.blockedAt;
      this.changedMapElementIds.set(mapElementId, !isBlocked);
    }
    this._onChange$.next();
  }

  reset() {
    this.changedMapElementIds.clear();
    this._onChange$.next();
  }

  applyChanges(mapElements: MapElementDto[]): MapElementDto[] {
    return mapElements.map((mapElement) => {
      const clone = structuredClone(mapElement);
      if (
        clone.elementType !== ElementType.ROBOT_EDGE ||
        !this.changedMapElementIds.has(clone.id)
      ) {
        return clone;
      }
      const props = clone.properties as RobotEdgePropertiesDto;

      if (!props) {
        return clone;
      }
      if (props.blockedAt) {
        delete props.blockedAt;
        delete props.blockedUntil;
      } else {
        props.blockedAt = new Date();
      }

      return clone;
    });
  }

  getChanges(): BlockageUpdate[] {
    return Array.from(this.changedMapElementIds.entries()).map(
      ([mapElementId, isBlocked]) => {
        return <BlockageUpdate>{ mapElementId, isBlocked };
      },
    );
  }
}
